import React, { useCallback, useEffect, useState, useRef } from "react";
import styles from "./verifyModal.module.scss";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import main_apis from "api/main";
import settings_api from "api/settings";
import toast from "react-hot-toast";
import axios from "axios";
import { Spinner } from "@shopify/polaris";
import { useRouter } from "next/router";
import { BASE_URL, PARTNER360TOKEN } from "utils/Base";

const VerifyModal = ({ open, setOpen, getDashData }) => {
  const cookies = new Cookies();

  const [loading, setLoading] = useState(false);
  const [cred, setCred] = useState({ username: "", password: "" });

  const router = useRouter();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      minWidth: "600px",
      transform: "translate(-50%, -50%)",
      maxWidth: "1000px",
      background: "rgba(0,0,0,0)",
      border: "0",
      overflow: "visible",
      zIndex: "800",
    },
  };

  const get360Token = (e) => {
    e.preventDefault();
    setLoading(true);
    let body = {
      ...cred,
      shopId: cookies.get("shopId"),
      merchantToken: cookies.get("merchantToken"),
    };
    axios.post(`${BASE_URL}/wa/bsp/get-access-token`, body).then((res) => {
      if (res.status == 200) {
        localStorage.setItem("token360", JSON.stringify(res.data));
        getApiKey();
       
      }
    });
  };

  // const handleEnablePermission = () => {
  //   window.open('', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  //   axios
  //     .get("https://hub.360dialog.com/dashboard/app/6sgnqXPA/permissions")
  //     .then((res) => {
  //       console.log(res, "permission");
  //       localStorage.setItem("permission360", res.data);
  //       getApiKey();
  //     });
  // };

  const getApiKey = () => {
    let apiToken = JSON.parse(localStorage.getItem("token360"));

    let channel_id = JSON.parse(localStorage.getItem("three60"));
    main_apis
      .getApiKey(
        cookies.get("shopId"),
        cookies.get("merchantToken"),
        PARTNER360TOKEN,
        channel_id?.channels[0],
        apiToken?.data[0]?.access_token
      )
      .then((res) => {
        // send360Token(res.data);
        if (res.status == 200) {
          localStorage.setItem("three603", JSON.stringify(res.data));

          setLoading(false);
          setOpen(false);
          router.reload();
        }else{
          setLoading(false);
          setOpen(false);
          router.reload();
        }
      });
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      contentLabel=""
      style={customStyles}
    >
      <div className={styles.verifyModal}>
        <div className={styles.head}>
          <img
            src="https://hub.360dialog.com/dist/aa3a1c9718745e4b5cf6d31742e2ee74.svg"
            alt=""
          />
          <div className={styles.close} onClick={() => setOpen(false)}>
            Close
          </div>
        </div>
        <div className={styles.leftMain}>
          <h3>Login</h3>
          <div className={styles.hr}></div>
          {loading ? (
            <div className={styles.apiDelay}>
              Please waite a minute, your account is processing
            </div>
          ) : (
            <>
              <p>Enter your 360 credentials to connect with reviewbit</p>
              <form onSubmit={(e) => get360Token(e)} className={styles.form}>
                <div className={styles.inputItem}>
                  <label htmlFor="">Email</label>
                  <input
                    onChange={(e) =>
                      setCred({ ...cred, username: e.target.value })
                    }
                    value={cred.username}
                    type="email"
                  />
                </div>
                <div className={styles.inputItem}>
                  <label htmlFor="">Password</label>
                  <input
                    onChange={(e) =>
                      setCred({ ...cred, password: e.target.value })
                    }
                    value={cred.password}
                    type="password"
                  />
                </div>
                <div className={styles.hr}></div>

                <div className={styles.actions}>
                  {loading ? (
                    <button className={styles.btnLoader}>
                      <Spinner
                        accessibilityLabel="Small spinner example"
                        size="small"
                      />
                    </button>
                  ) : (
                    <button type="submit" className={styles.agree}>
                      Login
                    </button>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VerifyModal;
